/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import OccupancyDiscrepancyModal from '@hkm/components/shared/OccupancyDiscrepancy/Modal/OccupancyDiscrepancyModal';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { RoomOccupancy } from '@hkm/shared/roomOccupancy/roomOccupancy';

import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import {
  AlignItems,
  ButtonPattern,
  ChipsColor,
  ChipsSize,
  IconName,
  Orientation,
  TextColor,
} from '@ac/web-components';

export interface HousekeepingOccupancyProps extends Testable {
  occupancy: RoomOccupancy;
  onSave: (count: GuestCount) => void;
  onDelete: () => void;
}
const OccupancyDiscrepancyButton: FC<HousekeepingOccupancyProps> = ({
  testSelector,
  onDelete,
  onSave,
  occupancy,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const hasManageHousekeepingPermission = usePermission(
    ...HousekeepingManagePermissionsConfig
  );

  if (!hasManageHousekeepingPermission && !occupancy.hasReport) {
    return null;
  }

  return (
    <>
      {occupancy.canReport && (
        <div className="occupancy-discrepancy">
          <ac-text-group
            label={t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.LABEL')}
            orientation={Orientation.vertical}
            data-test-selector={testSelector}
          >
            <ac-slot-container>
              {occupancy.hasReport ? (
                <ac-chips
                  color={ChipsColor.white}
                  size={ChipsSize.sm}
                  onRemoveCallback={() => onDelete()}
                  onClick={() => setModalOpen(true)}
                >
                  <ac-flex
                    class="ac-gap-sm ac-padding-left-sm height-percentage-100"
                    alignItems={AlignItems.center}
                  >
                    <ac-text
                      color={
                        occupancy.hasAdultsDiscrepancy
                          ? TextColor.alert
                          : undefined
                      }
                    >
                      {t('GLOBAL.ADULTS', {
                        count: occupancy.reportedAdults,
                      })}
                    </ac-text>

                    <ac-text
                      color={
                        occupancy.hasChildrenDiscrepancy
                          ? TextColor.alert
                          : undefined
                      }
                    >
                      {t('GLOBAL.CHILDREN', {
                        count: occupancy.reportedChildren,
                      })}
                    </ac-text>
                  </ac-flex>
                </ac-chips>
              ) : (
                <ac-button
                  pattern={ButtonPattern.tertiary}
                  onClickCallback={() => setModalOpen(true)}
                  data-test-selector={`${testSelector}-button`}
                >
                  <ac-button-content
                    icon={IconName.plus}
                    text={t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.ADD')}
                  />
                </ac-button>
              )}
            </ac-slot-container>
          </ac-text-group>
          <OccupancyDiscrepancyModal
            open={isModalOpen}
            occupancy={occupancy}
            onClose={() => setModalOpen(false)}
            testSelector={testSelector}
            onSave={onSave}
          />
        </div>
      )}
    </>
  );
};

export default memo(OccupancyDiscrepancyButton);
