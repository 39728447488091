/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';

import { TextSize } from '@ac/web-components';

interface GuestNumbersTemplateProps {
  count: GuestCount;
}

const GuestNumbersTemplate: FC<GuestNumbersTemplateProps> = (
  props: GuestNumbersTemplateProps
) => {
  const { t } = useTranslation();

  return (
    <ac-text size={TextSize.md}>
      {`${t('GLOBAL.ADULTS', { count: props.count.adultCount })} - ${t('GLOBAL.CHILDREN', { count: props.count.childCount })}`}
    </ac-text>
  );
};

export default memo(GuestNumbersTemplate);
