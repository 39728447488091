/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as appActions from '@hkm/components/App/domain/actions';
import FinalizeTaskProcess from '@hkm/components/Attendant/RoomDetails/body/FinalizeTaskProcess/FinalizeTaskProcess';
import * as actions from '@hkm/components/Attendant/shared/domain/actions';
import { AttendantRoomUpdateData } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentTask';
import { selectSheetGroupedRooms } from '@hkm/components/Attendant/shared/domain/selectors';
import { AttendantTaskActionType } from '@hkm/components/Attendant/shared/enum/attendantTaskActionType';
import { isTaskProgress } from '@hkm/components/Attendant/shared/utils/isTaskProgress';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { GuestServiceModalFormState } from '@hkm/components/shared/GuestService/modal/form/useFormState';
import GuestServiceModal, {
  GuestServiceModalMode,
} from '@hkm/components/shared/GuestService/modal/GuestServiceModal';
import { useFinalizeTaskProcessModalPresenterContext } from '@hkm/features/attendant/modals/finalizeTaskProcess/presenter/context';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import {
  AttendantAssignmentTaskDetails,
  AttendantTaskProgressType,
  HousekeepingStatus,
} from '@ac/library-api';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { ModalResultType } from '@ac/react-infrastructure';
import {
  ButtonFill,
  ButtonPattern,
  FlexGap,
  FooterSize,
  FooterTheme,
} from '@ac/web-components';

export interface AttendantFabNavigationProps extends Childless {
  sheetId: string;
  room: AttendantRoom;
  activeTask: AttendantAssignmentTaskDetails;
}

const AttendantFabContainer: FC<AttendantFabNavigationProps> = (
  props: AttendantFabNavigationProps
) => {
  if (props.activeTask.progress?.code === AttendantTaskProgressType.Completed) {
    return null;
  }

  const { t } = useTranslation();
  const statusSelectionProvider = useFinalizeTaskProcessModalPresenterContext();
  const dispatch = useDispatch();
  const ref = useRef<HTMLElement | null>(null);
  const handleRef = (e: HTMLElement | undefined) => (ref.current = e ?? null);

  const [skipCleaningModal, setSkipCleaningModal] = useState<
    GuestServiceModalMode | undefined
  >(undefined);
  const [finalizeTaskWithStatus, setFinalizeTaskWithStatus] =
    useState<HousekeepingStatus>();

  const prefix = 'attendant-fab-navigation';
  const isRoomInQueue = props.room.queueRoomEntries.length > 0;
  const groupedRooms = useSelector(selectSheetGroupedRooms);
  const effectiveValues = useSelector(selectEffectiveValues);
  const { activeAdHocGuestServices, filteredGuestServices } =
    useGuestServices();

  const bypassTrackingAttendantInRoom =
    effectiveValues?.bypassTrackingAttendantInRoom;

  const areRoomsInProgress = groupedRooms.get(
    AttendantTaskProgressType.InProgress
  )?.length;
  const arePausedRooms = groupedRooms.get(
    AttendantTaskProgressType.Paused
  )?.length;

  const activeRoom =
    (areRoomsInProgress &&
      groupedRooms.get(AttendantTaskProgressType.InProgress)?.[0]) ||
    (arePausedRooms &&
      groupedRooms.get(AttendantTaskProgressType.Paused)?.[0]) ||
    undefined;

  const showStartTaskButton =
    (!activeRoom || isRoomInQueue) &&
    isTaskProgress([AttendantTaskProgressType.Pending], props.activeTask) &&
    !bypassTrackingAttendantInRoom;

  const showResumeTaskButton =
    !areRoomsInProgress &&
    isTaskProgress(
      [AttendantTaskProgressType.Paused, AttendantTaskProgressType.Skipped],
      props.activeTask
    ) &&
    !bypassTrackingAttendantInRoom;
  const showPauseTaskButton = !arePausedRooms && !bypassTrackingAttendantInRoom;
  const showSkipTaskButton =
    !isTaskProgress([AttendantTaskProgressType.Skipped], props.activeTask) &&
    !isTaskProgress([AttendantTaskProgressType.InProgress], props.activeTask) &&
    !bypassTrackingAttendantInRoom;

  const enabledGuestServiceStatuses = filteredGuestServices(
    [GuestServiceStatusType.ServiceRequested],
    activeAdHocGuestServices
  );

  const guestServiceModalDescription =
    !effectiveValues?.bypassHKAttendantSummary &&
    activeRoom &&
    activeRoom?.id !== props.room.id
      ? t(
          'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.SKIPPING_CLEANING_MODAL.CONTENT',
          {
            activeRoomNumber: activeRoom?.roomNumber,
            taskProgress: t(
              `GLOBAL.TASK_PROGRESS_TYPE.${activeRoom?.activeTask?.progress?.code}`
            ),
            nextRoomNumber: props.room.roomNumber,
          }
        )
      : undefined;

  // general task handler
  const getTaskPayload = (
    taskActionType: AttendantTaskActionType,
    room: AttendantRoom,
    activeTask: AttendantAssignmentTaskDetails,
    attendantRoomUpdateData?: AttendantRoomUpdateData
  ) => ({
    roomId: room.id,
    actionType: taskActionType,
    taskId: activeTask.id,
    data: attendantRoomUpdateData,
    roomNumber: room.roomNumber ?? '',
  });

  const runTask = useCallback(
    (
      taskActionType: AttendantTaskActionType,
      attendantRoomUpdateData?: AttendantRoomUpdateData
    ) =>
      dispatch(
        actions.runAttendantTask.trigger(
          getTaskPayload(
            taskActionType,
            props.room,
            props.activeTask,
            attendantRoomUpdateData
          )
        )
      ),
    [dispatch, props.activeTask, props.room]
  );

  // task handlers
  const startTask = useCallback(
    () => runTask(AttendantTaskActionType.Start),
    [runTask]
  );
  const pauseTask = useCallback(
    () => runTask(AttendantTaskActionType.Pause),
    [runTask]
  );
  const resumeTask = useCallback(
    () => runTask(AttendantTaskActionType.Resume),
    [runTask]
  );

  // finalize task handlers
  const resetProcessHandler = useCallback(
    () => setFinalizeTaskWithStatus(undefined),
    []
  );
  const finalizeProcessHandler = useCallback(
    (data: AttendantRoomUpdateData) => {
      runTask(AttendantTaskActionType.Complete, data);
      setFinalizeTaskWithStatus(undefined);
    },
    [runTask]
  );

  const completeTask = useCallback(async () => {
    const result = await statusSelectionProvider.show();

    if (result.resultType === ModalResultType.Confirmed) {
      setFinalizeTaskWithStatus(result.output.housekeepingStatus);
    } else {
      resetProcessHandler();
    }
  }, [resetProcessHandler, statusSelectionProvider]);

  const skipTask = useCallback(
    (guestServiceModalFormState: GuestServiceModalFormState) =>
      runTask(AttendantTaskActionType.Skip, {
        untilTime: guestServiceModalFormState.serviceDeferredUntil,
        newGuestServiceStatus: guestServiceModalFormState.serviceType,
      }),
    [runTask]
  );

  const cancelActiveTaskAndStartForThisRoom = useCallback(() => {
    if (!activeRoom || !activeRoom.activeTask) {
      return;
    }

    return dispatch(
      actions.runAttendantTasks.trigger({
        tasks: [
          getTaskPayload(
            AttendantTaskActionType.Cancel,
            activeRoom,
            activeRoom.activeTask
          ),
          getTaskPayload(
            AttendantTaskActionType.Start,
            props.room,
            props.activeTask
          ),
        ],
        successMessage: t(
          'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.TASKS.SUCCESS_MESSAGE.SKIP_AND_START_ANOTHER',
          { roomNumber: activeRoom?.roomNumber }
        ),
      })
    );
  }, [t, dispatch, props.room, activeRoom, props.activeTask]);

  // skip task handlers
  const skipCleaningModalClose = useCallback(
    () => setSkipCleaningModal(undefined),
    []
  );
  const skipCleaningModalOpen = useCallback(() => {
    if (
      !!props.room.currentMainReservation &&
      !!props.room.currentMainReservation.currentDayOfReservation &&
      enabledGuestServiceStatuses.length > 0
    ) {
      setSkipCleaningModal(GuestServiceModalMode.AdHoc);
    } else {
      skipTask({});
    }
  }, [
    props.room.currentMainReservation,
    enabledGuestServiceStatuses,
    skipTask,
  ]);

  const onStartTask = useCallback(() => {
    if (!activeRoom) {
      return startTask();
    }
    const isCurrentRoomInQueue = props.room.queueRoomEntries.length > 0;
    if (isCurrentRoomInQueue) {
      return cancelActiveTaskAndStartForThisRoom();
    }

    return setSkipCleaningModal(GuestServiceModalMode.AdHoc);
  }, [
    props.room.queueRoomEntries.length,
    activeRoom,
    cancelActiveTaskAndStartForThisRoom,
    startTask,
  ]);

  useEffect(() => {
    dispatch(appActions.registerBottomMessageOffsetRef(ref));

    return () => {
      dispatch(appActions.unregisterBottomMessageOffsetRef(ref));
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ac-footer
        ref={handleRef}
        class="ac-footer-no-padding"
        theme={FooterTheme.default}
        size={FooterSize.dynamicLg}
      >
        <ac-flex grow={true} columnGap={FlexGap.md}>
          {showSkipTaskButton && (
            <ac-flex grow={true}>
              <ac-button
                onClick={skipCleaningModalOpen}
                data-test-selector={formatTestSelector(prefix, 'skip-task')}
                id={`${prefix}-skip-task`}
                pattern={ButtonPattern.tertiary}
                fill={ButtonFill.max}
              >
                <ac-button-content
                  text={t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.FOOTER.SKIP')}
                />
              </ac-button>
            </ac-flex>
          )}

          {showStartTaskButton && (
            <ac-flex grow={true}>
              <ac-button
                id={`${prefix}-start-task`}
                onClick={onStartTask}
                data-test-selector={formatTestSelector(prefix, 'start-task')}
                fill={ButtonFill.max}
              >
                <ac-button-content
                  text={t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.FOOTER.START')}
                />
              </ac-button>
            </ac-flex>
          )}

          {(isTaskProgress(
            [AttendantTaskProgressType.InProgress],
            props.activeTask
          ) ||
            bypassTrackingAttendantInRoom) && (
            <>
              {showPauseTaskButton && (
                <ac-flex grow={true}>
                  <ac-button
                    onClick={pauseTask}
                    data-test-selector={formatTestSelector(
                      prefix,
                      'pause-task'
                    )}
                    id={`${prefix}-pause-task`}
                    pattern={ButtonPattern.tertiary}
                    fill={ButtonFill.max}
                  >
                    <ac-button-content
                      text={t(
                        'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.FOOTER.PAUSE'
                      )}
                    />
                  </ac-button>
                </ac-flex>
              )}
              <ac-flex grow={true}>
                <ac-button
                  id={`${prefix}-complete-task`}
                  onClick={completeTask}
                  data-test-selector={formatTestSelector(
                    prefix,
                    'complete-task'
                  )}
                  fill={ButtonFill.max}
                >
                  <ac-button-content
                    text={t(
                      'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.FOOTER.FINISH'
                    )}
                  />
                </ac-button>
              </ac-flex>
            </>
          )}

          {showResumeTaskButton && (
            <ac-flex grow={true}>
              <ac-button
                id={`${prefix}-resume-task`}
                onClick={resumeTask}
                data-test-selector={formatTestSelector(prefix, 'resume-task')}
                fill={ButtonFill.max}
              >
                <ac-button-content
                  text={t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.FOOTER.RESUME')}
                />
              </ac-button>
            </ac-flex>
          )}
        </ac-flex>
      </ac-footer>

      <GuestServiceModal
        onConfirm={skipTask}
        onClose={skipCleaningModalClose}
        mode={skipCleaningModal}
        room={props.room}
        excludeGuestService={[GuestServiceStatusType.ServiceRequested]}
        description={guestServiceModalDescription}
      />

      <FinalizeTaskProcess
        room={props.room}
        initializedProcess={finalizeTaskWithStatus}
        resetProcessHandler={resetProcessHandler}
        finalizeProcessHandler={finalizeProcessHandler}
      />
    </>
  );
};

export default memo(AttendantFabContainer);
