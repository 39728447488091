/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */

import { PropsWithChildren, useCallback } from 'react';
import { MOBILE_HEADER_SELECTORS } from '@hkm/features/app/features/header/Header.selectors';
import {
  MobileHeaderProps,
  MobileHeaderType,
} from '@hkm/publicApi/components/mobileHeader';

import {
  AlignItems,
  Color,
  CssUnit,
  IconName,
  Size,
  Spacing,
} from '@ac/web-components';

export const MobileHeader = ({
  level = { type: MobileHeaderType.Primary },
  title,
  children,
}: PropsWithChildren<MobileHeaderProps>): JSX.Element => {
  const onClick = useCallback(() => {
    const presenter = window.ACP?.presenters?.menu;

    if (level.type === MobileHeaderType.Secondary) {
      return level.onBackRedirection();
    }

    return presenter?.show({
      hideCloseButton: false,
    });
  }, [level]);

  return (
    <ac-mobile-header headerTitle={title} class="bg-white">
      <div className="display-contents" slot="headerLeftSlot">
        <ac-flex
          alignItems={AlignItems.center}
          class="height-percentage-100 ac-spacing-right-lg"
        >
          <ac-icon-button
            icon={{
              color: Color.gray,
              size: Size.lg,
              icon:
                level.type === MobileHeaderType.Primary
                  ? IconName.menu
                  : IconName.arrowLeft,
            }}
            buttonHeight={{ value: 100, unit: CssUnit.percent }}
            horizontalSpacing={{ start: Spacing.lg }}
            data-test-selector={MOBILE_HEADER_SELECTORS.MENU}
            onClick={onClick}
          />
        </ac-flex>
      </div>
      <div className="display-contents" slot="headerRightSlot">
        <ac-flex alignItems={AlignItems.center} class="height-percentage-100">
          {children}
        </ac-flex>
      </div>
    </ac-mobile-header>
  );
};
