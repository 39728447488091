import { useCallback } from 'react';
import { LOG_KEYS } from '@hkm/constants/log.constants';
import {
  SingleSpaLifecycle,
  useMicroFrontendsLifecycleInjections,
} from '@hkm/features/microFrontends/commands/useMicroFrontendsLifecycleInjections';
import { useMicroFrontendsLogger } from '@hkm/features/microFrontends/commands/useMicroFrontendsLogger';
import { MissingAppUrlError } from '@hkm/features/microFrontends/errors/MissingAppUrlError';
import { MissingLoaderError } from '@hkm/features/microFrontends/errors/MissingLoaderError';
import { UnableToLoadError } from '@hkm/features/microFrontends/errors/UnableToLoadError';
import { UnexpectedError } from '@hkm/features/microFrontends/errors/UnexpectedError';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';

import { loadReactModule } from '@ac/library-utils/dist/utils/micro-frontends/loading/page/react';
import { AppToRender } from '@ac/library-utils/dist/utils/micro-frontends/rendering/shared';

const EXPORTED_EMPTY_COMPONENT = {
  bootstrap: [],
  mount: [],
  unmount: [],
};

interface Output {
  load(appUrl?: string): Promise<SingleSpaLifecycle>;
}

interface ReactModule {
  getComponents(): Promise<unknown>;
  getIndexInContainer(): Promise<AppToRender>;

  /**
   * @private
   * This method should be only available in hk/tm repo runtime
   * Avoid adding it to lib-utils, it will be replaced in future by mobile container
   */
  getIndexInMobileHousekeepingContainer(): Promise<AppToRender>;
}

const MISSING_LOADER_ERROR = 'Missing loader to load external module.';
const FAILED_TO_FETCH_ERROR = 'Failed to fetch';

export function useMicroFrontendsReactLoader(): Output {
  const { setError } = useMicroFrontendsContext();
  const { inject } = useMicroFrontendsLifecycleInjections();
  const { log } = useMicroFrontendsLogger(LOG_KEYS.MICRO_FRONTENDS.LOAD_FAILED);

  const load = useCallback(
    async (appUrl?: string): Promise<SingleSpaLifecycle> => {
      if (!appUrl) {
        const error = new MissingAppUrlError();

        await log(error);
        setError(error);

        return EXPORTED_EMPTY_COMPONENT;
      }
      try {
        const acModule = (await loadReactModule(appUrl)) as ReactModule;
        const exportedApp =
          await acModule.getIndexInMobileHousekeepingContainer();

        return inject(exportedApp);
      } catch (error) {
        const knownExceptions = {
          [FAILED_TO_FETCH_ERROR]: new UnableToLoadError(),
          [MISSING_LOADER_ERROR]: new MissingLoaderError(),
        };

        const exception =
          knownExceptions[error.message] ?? new UnexpectedError(error.message);

        setError(exception);

        await log(exception);
      }

      return EXPORTED_EMPTY_COMPONENT;
    },
    [inject, log, setError]
  );

  return {
    load,
  };
}
