/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import DatetimeFormElement, {
  DatetimeVariant,
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import { ReservationDatesUtils } from '@hkm/domain/utils/reservation/dates/ReservationDates';
import { useActiveProperty } from '@hkm/features/property/useActiveProperty';
import { useDetectedReservationMovement } from '@hkm/shared/reservations/reservationMovementHooks';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { FlexDirection, FlexGap } from '@ac/web-components';

import './ReservationMovementDashboardItem.css';

interface ReservationMovementDashboardItemProps extends Testable {
  reservation: UnifiedReservationDetails;
}

const ReservationMovementDetailsItem: FC<
  ReservationMovementDashboardItemProps
> = ({ reservation }) => {
  const { t } = useTranslation();
  const { businessDate, property } = useActiveProperty();

  const prefix = 'reservation-movements';
  const { moveIn, moveOut } = useDetectedReservationMovement(
    [reservation],
    (res) => res.status?.code as ReservationStatus,
    { noTimeLabel: true }
  );

  const hasReservationMovement = moveIn || moveOut;
  const hasDayUse = hasReservationMovement && reservation.isDayUse;
  const isInhouse = reservation?.status?.code === ReservationStatus.IH;
  const options = {
    businessDate,
    propertyCheckOutTime: property?.checkOutTime ?? '',
    propertyCheckInTime: property?.checkInTime ?? '',
  };
  const input = {
    reservation,
    options,
    resolveEtaEtdGuaranteed: true,
  };
  const arrivalDate =
    ReservationDatesUtils.getReservationArrivalDateTime(input);
  const departureDate =
    ReservationDatesUtils.getReservationDepartureDateTime(input);

  return (
    <ac-flex
      class={prefix}
      direction={FlexDirection.column}
      rowGap={FlexGap.md}
    >
      {isInhouse && (arrivalDate || departureDate) && (
        <>
          {arrivalDate && (
            <DatetimeFormElement
              label={t('ROOM_DETAILS.ARRIVAL_DATE')}
              timeLabel={t('GLOBAL.ETA')}
              datetime={arrivalDate}
              smallText={true}
              variant={DatetimeVariant.datetime}
            />
          )}

          {departureDate && (
            <DatetimeFormElement
              label={t('ROOM_DETAILS.DEPARTURE_DATE')}
              timeLabel={t('GLOBAL.ETD')}
              datetime={departureDate}
              smallText={true}
              variant={DatetimeVariant.datetime}
            />
          )}
        </>
      )}

      {hasDayUse && !isInhouse && (reservation.eta || reservation.etd) && (
        <>
          {reservation.eta && (
            <DatetimeFormElement
              label={t('ROOM_DETAILS.ARRIVAL_DATE')}
              timeLabel={t('GLOBAL.ETA')}
              datetime={reservation.eta}
              smallText={true}
            />
          )}
          {reservation.etd && (
            <DatetimeFormElement
              label={t('ROOM_DETAILS.DEPARTURE_DATE')}
              timeLabel={t('GLOBAL.ETD')}
              datetime={reservation.etd}
              smallText={true}
            />
          )}
        </>
      )}

      {hasReservationMovement && !hasDayUse && !isInhouse && (
        <>
          {moveOut && (
            <DatetimeFormElement
              {...moveOut}
              label={t('ROOM_DETAILS.DEPARTURE_DATE')}
              smallText={true}
            />
          )}
          {moveIn && (
            <DatetimeFormElement
              {...moveIn}
              label={t('ROOM_DETAILS.ARRIVAL_DATE')}
              smallText={true}
            />
          )}
        </>
      )}
    </ac-flex>
  );
};

export default memo(ReservationMovementDetailsItem);
