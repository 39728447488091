/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, HTMLAttributes, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DayUseBadge from '@hkm/components/shared/DayUseBadge/DayUseBadge';
import EtaEtdGuaranteedBadge from '@hkm/components/shared/EtaEtdGuaranteedBadge/EtaEtdGuaranteedBadge';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  JustifyContent,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';
import { FlexGap, Orientation } from '@ac/web-components';

export enum DatetimeVariant {
  onlyTime = 'only-time',
  onlyDate = 'only-date',
  datetime = 'datetime',
}

export interface DatetimeFormElementProps
  extends HTMLAttributes<HTMLDivElement>,
    Testable,
    Styleable {
  smallLabel?: boolean;
  smallText?: boolean;
  datetime?: string;
  label?: string;
  timeLabel?: string;
  variant?: DatetimeVariant;
  color?: MobileColor;
  flatAndJustify?: JustifyContent;
  isEtaEtdGuaranteed?: boolean;
  isDayUse?: boolean;
}

const DatetimeFormElement: FC<DatetimeFormElementProps> = ({
  label = '',
  variant = DatetimeVariant.datetime,
  className = '',
  isEtaEtdGuaranteed = false,
  isDayUse = false,
  ...props
}) => {
  const formats = useSelector(selectPropertyDateFormats);
  const textSize = props.smallText ? TextSize.Main2 : TextSize.Main1;
  const showTime = !(variant === DatetimeVariant.onlyDate);
  const showDate = !(variant === DatetimeVariant.onlyTime);
  const splitDatetimeLabels = !!props.timeLabel;

  const date = useMemo(
    () =>
      props.datetime && (
        <AcText
          color={props.color || MobileColor.Black}
          size={textSize}
          {...getTestSelectorProp(props.testSelector, 'dateText')}
        >
          {dayjs(props.datetime).format(formats.shortDate)}
        </AcText>
      ),
    [
      props.datetime,
      formats.shortDate,
      textSize,
      props.testSelector,
      props.color,
    ]
  );

  const time = useMemo(
    () =>
      props.datetime && (
        <AcText
          color={props.color || MobileColor.Gray3}
          size={textSize}
          {...getTestSelectorProp(props.testSelector, 'timeText')}
        >
          {dayjs(props.datetime).format(formats.time)}
        </AcText>
      ),
    [props.datetime, formats.time, textSize, props.testSelector, props.color]
  );

  const inner = useMemo(
    () => (
      <ac-flex grow={true}>
        {showDate && (
          <ac-box>
            <ac-text-group
              label={label}
              orientation={
                props.flatAndJustify
                  ? Orientation.horizontal
                  : Orientation.vertical
              }
              class="date-time-element"
            >
              {isEtaEtdGuaranteed && (
                <ac-box>
                  <EtaEtdGuaranteedBadge />
                </ac-box>
              )}
              {isDayUse && (
                <ac-box>
                  <DayUseBadge />
                </ac-box>
              )}
              <ac-flex columnGap={FlexGap.sm}>
                {date}
                {!splitDatetimeLabels && showTime && time}
              </ac-flex>
            </ac-text-group>
          </ac-box>
        )}

        {splitDatetimeLabels && showTime && (
          <ac-box>
            <ac-text-group
              class="time-element"
              orientation={
                props.flatAndJustify
                  ? Orientation.horizontal
                  : Orientation.vertical
              }
              label={props.timeLabel}
            >
              {isEtaEtdGuaranteed && (
                <ac-box>
                  <EtaEtdGuaranteedBadge />
                </ac-box>
              )}
              {isDayUse && (
                <ac-box>
                  <DayUseBadge />
                </ac-box>
              )}
              {time && <ac-text>{time}</ac-text>}
            </ac-text-group>
          </ac-box>
        )}
      </ac-flex>
    ),
    [
      showDate,
      label,
      props.flatAndJustify,
      props.timeLabel,
      isEtaEtdGuaranteed,
      isDayUse,
      date,
      splitDatetimeLabels,
      showTime,
      time,
    ]
  );

  return <div className={className}>{inner}</div>;
};

export default memo(DatetimeFormElement);
