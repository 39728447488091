import { initReactI18next } from 'react-i18next';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { LOG_KEYS } from '@hkm/constants/log.constants';
import { Console } from '@hkm/utils/console';
import { dayjs } from '@hkm/utils/dayjs-extended';
import { Kibana } from '@hkm/utils/kibana';
import i18n, { TFunction } from 'i18next';
import backend from 'i18next-http-backend';

import { setUpI18next } from '@ac/library-utils/dist/translations/react';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
    returnDetails: false;
    returnObjects: false;
  }
}

const dateLocaleMap = {
  'zh-Hans': 'zh-cn',
  'zh-Hant': 'zh-cn',
};

registerI18nListeners();

export const initI18n = async (
  namespaces?: string[] | string
): Promise<TFunction> => {
  return setUpI18next(i18n, {
    plugins: {
      backend,
      initReactI18next,
    },
    moduleName: 'housekeepingMobile',
    useRemoteTranslations: true,
    repositoryName: 'frontend-housekeeping-mobile',
    initOptions: {
      returnNull: false,
      returnDetails: false,
      returnObjects: false,
      fallbackLng: {
        'zh-Hans': ['zh-Hant', APP_CONSTANTS.FALLBACK_LANGUAGE],
        default: [APP_CONSTANTS.FALLBACK_LANGUAGE],
      },
      ns: namespaces,
      debug: true,
      interpolation: {
        escapeValue: false,
      },
    },
  });
};

function registerI18nListeners() {
  i18n.on('languageChanged', (lng) => {
    dayjs.locale(dateLocaleMap[lng] ?? lng);
  });

  i18n.on('failedLoading', (lang, ns, message) => {
    const notification = `i18n failed to load language '${lang}' for namespace '${ns}': ${message}`;

    void Kibana.error(
      {
        message: notification,
        feLogKey: LOG_KEYS.TRANSLATIONS.LOAD_FAILED,
      },
      { forceSend: true }
    );
    void Console.error(notification);
  });
}

export default i18n;
