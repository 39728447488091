import {
  API_TIME_FORMAT,
  ComparisonDateUtils,
  DateUnit,
  DateUtils,
} from '@ac/library-utils/dist/utils';

interface Props {
  arrivalDate: string;
  departureDate: string;
  currentDate: string;
}

export interface Reservation {
  eta?: string;
  arrivalDate?: string;
  etd?: string;
  departureDate?: string;
  isDayUse?: boolean;
  guaranteedTimeOfArrival?: string;
  guaranteedTimeOfDeparture?: string;
  isTimeGuaranteed?: boolean;
}

export interface Options {
  businessDate?: string;
  propertyCheckOutTime?: string;
  propertyCheckInTime?: string;
}

interface Input {
  reservation?: Reservation;
  options: Options;
  resolveEtaEtdGuaranteed?: boolean;
}

const DEFAULT_TIME = '00:00:00';

interface TheMostAccurateStartDate {
  checkInTime?: string;
  eta?: string;
  arrivalDate?: string;
}

interface TheMostAccurateEndDate {
  checkOutTime?: string;
  etd?: string;
  departureDate?: string;
}

interface CurrentDayOfReservations {
  businessDate: string;
  arrivalDate: string;
}

export class ReservationDatesUtils {
  static isArrivalOrDeparture(props: Props): boolean {
    return (
      ComparisonDateUtils.isSameDay(props.currentDate, props.arrivalDate) ||
      ComparisonDateUtils.isSameDay(props.currentDate, props.departureDate)
    );
  }

  static getReservationArrivalDateTime({
    reservation,
    options,
    resolveEtaEtdGuaranteed,
  }: Input): string {
    if (reservation?.eta) {
      return DateUtils.serialization.formatDateTimeForApi(reservation.eta);
    }

    if (
      !!resolveEtaEtdGuaranteed &&
      !!reservation?.isTimeGuaranteed &&
      reservation.guaranteedTimeOfArrival
    ) {
      return DateUtils.serialization.formatDateTimeForApi(
        reservation.guaranteedTimeOfArrival
      );
    }

    const checkOutTime = DateUtils.time.setTime(
      reservation?.arrivalDate ?? '',
      options.propertyCheckOutTime ?? DEFAULT_TIME,
      API_TIME_FORMAT
    );

    const checkInTime = DateUtils.time.setTime(
      reservation?.arrivalDate ?? '',
      options.propertyCheckInTime ?? DEFAULT_TIME,
      API_TIME_FORMAT
    );

    return reservation?.isDayUse
      ? DateUtils.serialization.formatDateTimeForApi(checkOutTime)
      : DateUtils.serialization.formatDateTimeForApi(checkInTime);
  }

  static getReservationDepartureDateTime({
    reservation,
    options,
    resolveEtaEtdGuaranteed,
  }: Input): string {
    if (reservation?.etd) {
      return DateUtils.serialization.formatDateTimeForApi(reservation.etd);
    }

    if (
      !!resolveEtaEtdGuaranteed &&
      !!reservation?.isTimeGuaranteed &&
      reservation.guaranteedTimeOfDeparture
    ) {
      return DateUtils.serialization.formatDateTimeForApi(
        reservation.guaranteedTimeOfDeparture
      );
    }

    const checkOutTime = DateUtils.time.setTime(
      reservation?.departureDate ?? '',
      options.propertyCheckOutTime ?? DEFAULT_TIME,
      API_TIME_FORMAT
    );

    return DateUtils.serialization.formatDateTimeForApi(checkOutTime);
  }

  static takeTheMostAccurateStartDate(
    props: TheMostAccurateStartDate
  ): string | undefined {
    return props.checkInTime ?? props.eta ?? props.arrivalDate;
  }

  static takeTheMostAccurateEndDate(
    props: TheMostAccurateEndDate
  ): string | undefined {
    return props.checkOutTime ?? props.etd ?? props.departureDate;
  }

  static getCurrentDayOfReservation({
    businessDate,
    arrivalDate,
  }: CurrentDayOfReservations): number {
    return DateUtils.comparison.diff(businessDate, arrivalDate, DateUnit.Days);
  }
}
