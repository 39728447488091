import { LOG_KEYS } from '@hkm/constants/log.constants';
import { Console } from '@hkm/utils/console';
import { Kibana } from '@hkm/utils/kibana';

import { Setting } from '@ac/library-api';
import { SettingsPropertiesApi } from '@ac/library-api/dist/api/v0/configuration/settings';

interface Props {
  propertyId: string | undefined;
}

export async function fetchPropertyFavicon({
  propertyId,
}: Props): Promise<Blob | undefined> {
  if (!propertyId) {
    return undefined;
  }

  try {
    return (await SettingsPropertiesApi.getContent({
      pathParams: {
        propertyId,
        code: Setting.BrandingFavicon,
      },
    })) as Blob | undefined;
  } catch (error) {
    await Kibana.error(
      { message: error.message, feLogKey: LOG_KEYS.HKM_FAVICON.PROPERTY },
      { forceSend: true }
    );
    await Console.error(error);

    return undefined;
  }
}
