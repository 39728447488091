import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DatetimeFormElementProps,
  DatetimeVariant,
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import { ReservationDatesUtils } from '@hkm/domain/utils/reservation/dates/ReservationDates';
import { useActiveProperty } from '@hkm/features/property/useActiveProperty';
import { GroupedReservedKinds } from '@hkm/shared/reservedKind/groupedReservedKinds';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { ReservationStatus } from '@ac/library-api';

export interface DatetimeFormElementTimeRelatedProps
  extends Pick<
    DatetimeFormElementProps,
    'datetime' | 'label' | 'timeLabel' | 'variant'
  > {
  isDayUse: boolean;
  isEtaEtdGuaranteed: boolean;
  etaGuaranteed?: string;
  etdGuaranteed?: string;

  // for day use case
  eta?: string;
  etd?: string;
}

export interface ReservationMovementDatetimeFormElementProps {
  moveOut?: DatetimeFormElementTimeRelatedProps;
  moveIn?: DatetimeFormElementTimeRelatedProps;
}

interface Options {
  noTimeLabel?: boolean;
}

type StatusProvider = (
  reservation: UnifiedReservationDetails
) => ReservationStatus;

export function useReservationMovement(
  room: GroupedReservedKinds,
  statusProvider: StatusProvider,
  options?: Options
): ReservationMovementDatetimeFormElementProps {
  const moveOut = useReservationMovementOut(
    room.currentReservations,
    statusProvider,
    options
  );
  const moveIn = useReservationMovementIn(
    room.nextReservations,
    statusProvider,
    options
  );

  return useMemo(() => ({ moveOut, moveIn }), [moveOut, moveIn]);
}

export function useDetectedReservationMovement(
  reservations: UnifiedReservationDetails[] | undefined,
  statusProvider: StatusProvider,
  options?: Options
): ReservationMovementDatetimeFormElementProps {
  const moveOut = useReservationMovementOut(
    reservations,
    statusProvider,
    options
  );
  const moveIn = useReservationMovementIn(
    reservations,
    statusProvider,
    options
  );

  return useMemo(() => ({ moveOut, moveIn }), [moveOut, moveIn]);
}

export function useReservationMovementIn(
  reservations: UnifiedReservationDetails[] | undefined,
  statusProvider: StatusProvider,
  options: Options = {}
): DatetimeFormElementTimeRelatedProps | undefined {
  const { t } = useTranslation();
  const { businessDate, property } = useActiveProperty();
  const { noTimeLabel } = options;
  const dateOptions = {
    businessDate,
    propertyCheckOutTime: property?.checkOutTime ?? '',
    propertyCheckInTime: property?.checkInTime ?? '',
  };

  const sortedReservations = (reservations?.concat() || []).sort((a, b) =>
    dayjs(a.eta || a.arrivalDate).isAfter(b.eta || (b.arrivalDate ?? ''))
      ? 1
      : -1
  );

  const isAnyInHouse = sortedReservations.some(
    (reservation) => statusProvider(reservation) === ReservationStatus.IH
  );
  const firstDueInReservation = isAnyInHouse
    ? undefined
    : sortedReservations.find(
        (reservation) => statusProvider(reservation) === ReservationStatus.DI
      );

  const input = {
    reservation: firstDueInReservation,
    options: dateOptions,
    resolveEtaEtdGuaranteed: true,
  };
  const arrival = firstDueInReservation
    ? ReservationDatesUtils.getReservationArrivalDateTime(input)
    : undefined;
  const hasNoEta =
    !firstDueInReservation?.eta && !firstDueInReservation?.isTimeGuaranteed;

  return useMemo(
    () =>
      !arrival
        ? undefined
        : {
            datetime: arrival,
            eta: firstDueInReservation?.eta,
            etd: firstDueInReservation?.etd,
            label: t('GLOBAL.RESERVATION_MOVE_IN'),
            timeLabel: noTimeLabel ? undefined : t('GLOBAL.ETA'),
            variant: hasNoEta
              ? DatetimeVariant.onlyDate
              : DatetimeVariant.datetime,
            isDayUse: !!firstDueInReservation?.isDayUse,
            isEtaEtdGuaranteed:
              firstDueInReservation?.isTimeGuaranteed ?? false,
            etaGuaranteed: firstDueInReservation?.guaranteedTimeOfArrival,
            etdGuaranteed: firstDueInReservation?.guaranteedTimeOfDeparture,
          },
    [
      arrival,
      noTimeLabel,
      hasNoEta,
      t,
      firstDueInReservation?.eta,
      firstDueInReservation?.etd,
      firstDueInReservation?.guaranteedTimeOfArrival,
      firstDueInReservation?.guaranteedTimeOfDeparture,
      firstDueInReservation?.isDayUse,
      firstDueInReservation?.isTimeGuaranteed,
    ]
  );
}

export function useReservationMovementOut(
  reservations: UnifiedReservationDetails[] | undefined,
  statusProvider: StatusProvider,
  options: Options = {}
): DatetimeFormElementTimeRelatedProps | undefined {
  const { t } = useTranslation();
  const { noTimeLabel } = options;
  const { businessDate, property } = useActiveProperty();
  const dateOptions = {
    businessDate,
    propertyCheckOutTime: property?.checkOutTime ?? '',
    propertyCheckInTime: property?.checkInTime ?? '',
  };

  const sortedReservations = (reservations?.concat() || []).sort((a, b) =>
    dayjs(a.etd || a.departureDate).isBefore(b.etd || (b.departureDate ?? ''))
      ? 1
      : -1
  );

  const isAnyInHouse = sortedReservations.some(
    (reservation) => statusProvider(reservation) === ReservationStatus.IH
  );
  const lastDueOutReservation = isAnyInHouse
    ? undefined
    : sortedReservations.find(
        (reservation) => statusProvider(reservation) === ReservationStatus.DO
      );

  const input = {
    reservation: lastDueOutReservation,
    options: dateOptions,
    resolveEtaEtdGuaranteed: true,
  };
  const departure = lastDueOutReservation
    ? ReservationDatesUtils.getReservationDepartureDateTime(input)
    : undefined;
  const hasNoEtd =
    !lastDueOutReservation?.etd && !lastDueOutReservation?.isTimeGuaranteed;

  return useMemo(
    () =>
      !departure
        ? undefined
        : {
            datetime: departure,
            eta: lastDueOutReservation?.eta,
            etd: lastDueOutReservation?.etd,
            label: t('GLOBAL.RESERVATION_MOVE_OUT'),
            timeLabel: noTimeLabel ? undefined : t('GLOBAL.ETD'),
            variant: hasNoEtd
              ? DatetimeVariant.onlyDate
              : DatetimeVariant.datetime,
            isDayUse: !!lastDueOutReservation?.isDayUse,
            isEtaEtdGuaranteed:
              lastDueOutReservation?.isTimeGuaranteed ?? false,
            etaGuaranteed: lastDueOutReservation?.guaranteedTimeOfArrival,
            etdGuaranteed: lastDueOutReservation?.guaranteedTimeOfDeparture,
          },
    [
      departure,
      lastDueOutReservation?.eta,
      lastDueOutReservation?.etd,
      noTimeLabel,
      hasNoEtd,
      t,
      lastDueOutReservation?.isDayUse,
      lastDueOutReservation?.isTimeGuaranteed,
      lastDueOutReservation?.guaranteedTimeOfArrival,
      lastDueOutReservation?.guaranteedTimeOfDeparture,
    ]
  );
}
