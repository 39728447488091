import {
  initLoggerWithDefaultMiddlewares,
  Logger,
  LoggerService,
} from '@ac/library-api';

let instance: LoggerService | undefined;

export async function getLoggerInstance(): Promise<LoggerService | undefined> {
  if (!instance) {
    Logger.setAsMain();
    instance = await initLoggerWithDefaultMiddlewares('housekeepingMobile');
  }

  return instance;
}
