export const LOG_KEYS = {
  TRANSLATIONS: {
    LOAD_FAILED: 'TRANSLATIONS.LOAD_FAILED',
  },
  MICRO_FRONTENDS: {
    LOAD_FAILED: 'MICRO_FRONTENDS.LOAD_FAILED',
  },
  HOUSEKEEPING_DASHBOARD: {
    LOAD_FAILED: 'HOUSEKEEPING_DASHBOARD.LOAD_FAILED',
  },
  HKM_FAVICON: {
    CUSTOMER: 'HKM_FAVICON.CUSTOMER',
    PROPERTY: 'HKM_FAVICON.PROPERTY',
  },
};
