import { useCallback } from 'react';
import { Console } from '@hkm/utils/console';
import { Kibana } from '@hkm/utils/kibana';

interface Output {
  log(log: Error): Promise<void>;
}

export function useMicroFrontendsLogger(feLogKey: string | undefined): Output {
  const log = useCallback(
    async (error: Error): Promise<void> => {
      await Kibana.error(
        {
          message: error.message,
          feLogKey,
        },
        { forceSend: true }
      );
      await Console.error(error);
    },
    [feLogKey]
  );

  return {
    log,
  };
}
