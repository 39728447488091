import { LOG_KEYS } from '@hkm/constants/log.constants';
import { Console } from '@hkm/utils/console';
import { Kibana } from '@hkm/utils/kibana';

import { getCustomConfigWithoutPropertyHeader, Setting } from '@ac/library-api';
import { SettingsCustomerApi } from '@ac/library-api/dist/api/v0/configuration/settings';

export async function fetchCustomerFavicon(): Promise<Blob | undefined> {
  try {
    return (await SettingsCustomerApi.getContent({
      pathParams: {
        code: Setting.BrandingFavicon,
      },
      customConfig: getCustomConfigWithoutPropertyHeader(),
    })) as Blob | undefined;
  } catch (error) {
    await Kibana.error(
      { message: error.message, feLogKey: LOG_KEYS.HKM_FAVICON.CUSTOMER },
      { forceSend: true }
    );
    await Console.error(error);

    return undefined;
  }
}
