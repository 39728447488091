import * as appActions from '@hkm/components/App/domain/actions';
import { HousekeepingPagination } from '@hkm/components/Housekeeping/Dashboard/domain/interfaces';
import {
  selectHousekeepingDashboardFilters,
  selectHousekeepingDashboardPagination,
  selectHousekeepingDashboardSort,
} from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import { HousekeepingSort } from '@hkm/components/Housekeeping/shared/enum/housekeepingSort';
import { createHousekeepingApiFilter } from '@hkm/components/Housekeeping/shared/filters/housekeepingApiFilterFactory';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import {
  selectActiveProperty,
  selectBusinessDate,
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { LOG_KEYS } from '@hkm/constants/log.constants';
import { parseErrorToKibanaFormat } from '@hkm/shared/helpers/getErrorMessage';
import { getReservedKind } from '@hkm/shared/reservedKind/getReservedKind';
import { PAGINATION_PAGE_SIZE } from '@hkm/store/pagination/paginationConfig';
import { createPaginationSaga } from '@hkm/store/pagination/paginationSagaFactory';
import { createHousekeepingRoom } from '@hkm/types/housekeeping/factories/createHousekeepingRoom';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { Kibana } from '@hkm/utils/kibana';
import { Task } from '@redux-saga/types';
import {
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  FIQLOperators,
  HousekeepingRoomDetailsDto,
  LibraryApiResponse,
  PageResponse,
  PropertyDetails,
} from '@ac/library-api';
import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

import * as actions from './actions';

const DEFAULT_SORT: HousekeepingSort = HousekeepingSort.Sequence;

function* fetchHousekeepingDashboardPage(action: Action<number>) {
  try {
    const filters: HousekeepingFiltersData = yield select(
      selectHousekeepingDashboardFilters
    );
    const sort: HousekeepingSort = yield select(
      selectHousekeepingDashboardSort
    );
    const sorts: string =
      sort === DEFAULT_SORT
        ? `${sort}${FIQLOperators.or}${HousekeepingSort.RoomNumber}`
        : `${sort}${FIQLOperators.or}${DEFAULT_SORT}`;
    const businessDate: string = yield select(selectBusinessDate);
    const activeProperty: PropertyDetails = yield select(selectActiveProperty);

    const page: LibraryApiResponse<
      PageResponse<HousekeepingRoomDetailsDto, HousekeepingRoom>
    > = yield HousekeepingViewsApi.getHousekeepingDashboard({
      queryParams: {
        sort: sorts,
        filter: filters ? createHousekeepingApiFilter(filters) : undefined,
        query: filters.query ? filters.query : undefined,
        pageSize: PAGINATION_PAGE_SIZE,
        pageNumber: action.payload,
      },
    });
    const housekeepingRoomsPage: PageResponse<
      HousekeepingRoomDetailsDto,
      HousekeepingRoom
    > = {
      paging: page.data.paging,
      results: page.data.results.map((result) =>
        createHousekeepingRoom(
          result,
          businessDate,
          getReservedKind,
          activeProperty.checkInTime,
          activeProperty.checkOutTime
        )
      ),
    };

    yield put(actions.pagination.fetchedPage(housekeepingRoomsPage));
  } catch (error) {
    yield put(actions.pagination.error());
    yield Kibana.error(
      {
        message: parseErrorToKibanaFormat(error),
        feLogKey: LOG_KEYS.HOUSEKEEPING_DASHBOARD.LOAD_FAILED,
      },
      { forceSend: true }
    );
  }
}

function* patchChangedRoom(action: Action<string>) {
  try {
    const roomId: string = action.payload;
    const state: HousekeepingPagination = yield select(
      selectHousekeepingDashboardPagination
    );
    const hasRoom = !!state.page?.results.some(
      (roomToCheck) => roomToCheck.id === roomId
    );

    if (!hasRoom) {
      return;
    }

    const businessDate: string = yield select(selectBusinessDate);
    const activeProperty: PropertyDetails = yield select(selectActiveProperty);

    const room: LibraryApiResponse<HousekeepingRoomDetailsDto> =
      yield HousekeepingViewsApi.getHousekeepingRoomDetails({
        pathParams: { roomId },
      });

    const housekeepingRoom = createHousekeepingRoom(
      room.data,
      businessDate,
      getReservedKind,
      activeProperty.checkInTime,
      activeProperty.checkOutTime
    );

    yield put(actions.replaceChangedRoom(housekeepingRoom));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* detailsSagas() {
  yield fork(
    createPaginationSaga(
      actions.pagination,
      selectHousekeepingDashboardPagination
    )
  );
  yield takeLatest(
    actions.pagination.fetchPage,
    fetchHousekeepingDashboardPage
  );
  yield takeEvery(actions.patchChangedRoom, patchChangedRoom);
}

export default function* housekeepingDashboardSagas() {
  while (true) {
    const task: Task = yield fork(detailsSagas);
    yield take(appActions.clearAllViews);
    yield cancel(task);
  }
}
